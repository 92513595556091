import { Box, Center, Flex, Heading, Image, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import bgImage from '../assets/Our_Branches_BG.webp'
import foxyLogo from '../assets/foymatic_logo.webp'
import uprealLogo from '../assets/upreal_logo.webp'

// import laptop from '../assets/Laptop-resized.webp'
// import laptop1 from '../assets/Laptop1.webp'
import laptop from '../assets/laptop-new-foxy.webp'
import laptop1 from '../assets/laptop-new-upreal.webp'
// import getintouch from '../assets/Get_into_touch.webp'
import getintouch from '../assets/CTA.webp'

// import './App.css'
import { FaStarOfLife } from 'react-icons/fa'

const Branches = () => {

    const [animations, setAnimations] = useState({
        text: 'text-fade-up 1.25s linear 2s forwards',
        opacity: 0,
        image: 'fade-in 1.75s forwards ease-out',
    })

    const [animations2, setAnimations2] = useState({
        text: 'text-fade-up 1.25s linear 2s forwards',
        opacity: 0,
        image: 'fade-in 1.75s forwards ease-out',
    })

    const [animations3, setAnimations3] = useState({
        text: 'text-fade-up 1.25s linear 2s forwards',
        opacity: 0,
        image: 'fade-in 1.75s forwards ease-out',
    })

    const [getTouch, setGetTouch] = useState('get-in-touch 1.25s ease-in-out 2s forwards')

    const [display, setDisplay] = useState(0)
    const [count, setCount] = useState(0)

    useEffect(() => {
        const animationInterval = setTimeout(() => {
            setAnimations({
                text: 'text-fade-out 1.25s ease-in-out forwards',
                image: 'fade-out 1.25s ease-in-out forwards'
            })
            setTimeout(() => {
                setDisplay(1)
                setAnimations({
                    text: 'text-fade-up 1.25s linear 2s forwards',
                    opacity: 0,
                    image: 'fade-in 1.75s forwards ease-out',
                })

            }, 1750);
            setGetTouch('get-in-touch-float 3s ease-in-out infinite')
        }, 5000)

        const animation2Interval = setTimeout(() => {
            setAnimations2({
                text: 'text-fade-out 1.25s ease-in-out forwards',
                image: 'fade-out 1.25s ease-in-out forwards'
            })
            setTimeout(() => {
                setDisplay(0)
                setAnimations2({
                    text: 'text-fade-up 1.25s linear 2s forwards',
                    opacity: 0,
                    image: 'fade-in 1.75s forwards ease-out',
                })
                setCount((prev) => prev + 1)
            }, 1750);
        }, 11000)

        // const animation3Interval = setTimeout(() => {
        //     setAnimations3({
        //         text: 'text-fade-out 1.25s ease-in-out forwards',
        //         image: 'fade-out 1.25s ease-in-out forwards'
        //     })
        //     setTimeout(() => {
        //         setDisplay(0)
        //         setAnimations3({
        //             text: 'text-fade-up 1.25s linear 2s forwards',
        //             opacity:0,
        //             image: 'fade-in 1.75s forwards ease-out',
        //         })


        //     }, 1750);

        // }, 16500)

        return () => {
            window.clearTimeout(animationInterval)
            window.clearTimeout(animation2Interval)
            // window.clearTimeout(animation3Interval)
        };
    }, [count]);


    return (

        <Box bgColor='black' h={{ base: '100%', sm: '100%', md: '100vh' }} backgroundImage={bgImage} bgRepeat='no-repeat' bgPosition='center' bgSize={{ xl: '100%', lg: "100%", md: "110%", sm: '110%', base: '110%' }} mt={{ base: '1rem', sm: '3rem', md: '2rem' }} id='brands'>
            <Center>
                <Heading fontSize={{ xl: '24', lg: '24', md: '20', sm: '22', base: '22' }} color='white'><Text as='span' fontWeight='200' fontSize={{ xl: '24', lg: '24', md: '20', sm: '22', base: '22' }}>Ou</Text><Text as='span' fontFamily='"Pixelify Sans", sans-serif !important' fontSize={{ xl: '32', lg: '32', md: '28', sm: '30', base: '30' }} color='#ff5f35'>r</Text> Brands</Heading>
            </Center>
            <Box position='relative' overflow='hidden' h={{ base: '90%', sm: '90%', md: '90vh' }} display={{ xl: "block", lg: "block", md: "none", sm: "none", base: "none" }}>
                {display === 0 && <Flex
                    mt={{ xl: '2rem', lg: '1rem' }}
                    mx='auto'
                    w='88%'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Box ml='50px' w='max-content' minW='20%' mt='7.5rem'>
                        <Image src={foxyLogo} height='auto' w='190px' animation={animations.text} opacity={animations.opacity} />
                        <Flex gap={6} my={9} mt={9} animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Web App Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>iOS App Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Graphics Design</Text>
                        </Flex>
                    </Box>
                    <Box position='relative' transition='0.5s'>
                        <Image ml={{ xl: '2.1rem', lg: '0.5rem' }} src={laptop} height='auto' w={{ xl: '38rem', lg: '34rem' }} mr={10} animation={animations.image} />
                        <Image src={getintouch} transform='translate(0%, -30%)' opacity={0} animation={getTouch} position='absolute' top={{ xl: '4rem', lg: '3rem' }} right={{ xl: '-22px', lg: '-30px' }} height='auto' w={{ xl: '250px', lg: '190px' }} transition='0.75s' _hover={{ filter: 'drop-shadow(5px 10px 4px #878585)' }} cursor='pointer' onClick={() => window.location.href = 'https://foxymatic.com'} />
                    </Box>

                    <Box w='30%' mt='10rem'>
                        <Flex gap={6} my={9} mt='5rem' animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Android App Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>CMR/ERP Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations.text} opacity={animations.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>UI/UX Design</Text>
                        </Flex>
                    </Box>
                </Flex>}
                {display === 1 && <Flex
                    mt='2rem'
                    mx='auto'
                    w='88%'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Box ml='50px' w='max-content' minW='20%' mt='7.5rem'>
                        <Image src={uprealLogo} height='auto' w='190px' animation={animations2.text} opacity={animations2.opacity} />
                        <Flex gap={6} my={9} mt={9} animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Gamification</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Augmented Reality Solutions</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Virtual Reality Solutions</Text>
                        </Flex>
                    </Box>
                    <Box position='relative' transition='0.5s'>
                        <Image ml={{ xl: '2.1rem', lg: '0.5rem' }} src={laptop1} height='auto' w={{ xl: '38rem', lg: '34rem' }} mr={10} animation={animations2.image} />
                        <Image src={getintouch} position='absolute' top={{ xl: '4rem', lg: '3rem' }} right={{ xl: '-22px', lg: '-30px' }} height='auto' w={{ xl: '250px', lg: '190px' }} transition='0.75s' _hover={{ filter: 'drop-shadow(5px 10px 4px #878585)' }} cursor='pointer' animation={getTouch} />
                    </Box>

                    <Box w='30%' mt='10rem'>
                        <Flex gap={6} my={9} mt='5rem' animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>3D Animation Videos</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>3D Modeling</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations2.text} opacity={animations2.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Simulation</Text>
                        </Flex>
                    </Box>
                </Flex>}
                {/* {display === 2 && <Flex
                    mt='2rem'
                    mx='auto'
                    w='88%'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Box ml='50px' w='max-content' minW='20%' mt='7.5rem'>
                        <Image src={foxyLogo} height='auto' w='190px' animation={animations3.text}  opacity={animations3.opacity} />
                        <Flex gap={6} my={9} mt={9} animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Web App Development3</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>iOS App Development3</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Graphics Design3</Text>
                        </Flex>
                    </Box>
                    <Box position='relative' transition='0.5s'>
                        <Image ml={{ xl: '2.1rem', lg: '0.5rem' }} src={laptop} height='auto' w={{ xl: '38rem', lg: '34rem' }}  mr={10}  animation={animations3.image}/>
                        <Image src={getintouch} position='absolute' top={{ xl: '4rem', lg: '3rem' }} right={{xl:'-22px', lg:'-30px'}} height='auto' w={{ xl: '240px', lg: '160px' }}  transition='0.75s' _hover={{filter:'drop-shadow(5px 10px 4px #878585)'}} cursor='pointer' animation={getTouch}/>
                    </Box>

                    <Box w='30%' mt='10rem'>
                        <Flex gap={6} my={9} mt='5rem' animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>Android App Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>CMR/ERP Development</Text>
                        </Flex>
                        <Flex gap={6} my={9} animation={animations3.text}  opacity={animations3.opacity}>
                            <FaStarOfLife color='#4f4e4e' />
                            <Text color='white' fontSize='14'>UI/UX Design</Text>
                        </Flex>
                    </Box>
                </Flex>} */}
            </Box>
            <Box position='relative' overflow='hidden' h={{ base: '90%', sm: '90%', md: '90vh' }} display={{ xl: "none", lg: "none", md: "block", sm: "block", base: "block" }}>
                {display === 0 && <Flex
                    mt='2rem'
                    mx='auto'
                    w='100%'
                    flexDir='column'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Center>
                        <Image src={foxyLogo} height='auto' w='150px' animation={animations.text} opacity={animations.opacity} />
                    </Center>
                    <Box position='relative' transition='0.5s' mt={5}>
                        <Image ml='2.1rem' src={laptop} height='auto' w={{ base: '19rem', sm: '19rem', md: '25rem' }} mr={10} animation={animations.image} />
                        <Image src={getintouch} transform='translate(0%, -30%)' opacity={0} animation={getTouch} position='absolute' top={{ base: '2rem', sm: '2rem', md: '4rem' }} right='20px' height='auto' w={{ base: '140px', sm: '140px', md: '150px' }}  onClick={() => window.location.href = 'https://foxymatic.com'}/>
                    </Box>
                    <Flex gap={7} justifyContent='center' w='100%'>
                        <Box w='30%' minW='20%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Web App Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>iOS App Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Graphics Design</Text>
                            </Flex>
                        </Box>

                        <Box w='30%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Android App Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>CMR/ERP Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations.text} opacity={animations.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>UI/UX Design</Text>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>}
                {display === 1 && <Flex
                    mt='2rem'
                    mx='auto'
                    w='100%'
                    flexDir='column'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Center>
                        <Image src={uprealLogo} height='auto' w='150px' animation={animations2.text} opacity={animations2.opacity} />
                    </Center>
                    <Box position='relative' transition='0.5s' mt={5}>
                        <Image ml='2.1rem' src={laptop1} height='auto' w={{ base: '19rem', sm: '19rem', md: '25rem' }} mr={10} animation={animations2.image} />
                        <Image src={getintouch} position='absolute' top={{ base: '2rem', sm: '2rem', md: '4rem' }} animation={getTouch} right='20px' height='auto' w={{ base: '140px', sm: '140px', md: '150px' }} />
                    </Box>
                    <Flex gap={7} justifyContent='center' w='100%'>
                        <Box w='30%' minW='20%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Gamification</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Augmented Reality Solutions</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Virtual Reality Solutions</Text>
                            </Flex>
                        </Box>

                        <Box w='30%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>3D Animation Videos</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>3D Modeling</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations2.text} opacity={animations2.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Simulation</Text>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>}
                {/* {display === 2 && <Flex
                    mt='2rem'
                    mx='auto'
                    w='100%'
                    flexDir='column'
                    justifyContent='space-between'
                    alignItems='center'
                >
                    <Center>
                        <Image src={foxyLogo} height='auto' w='150px' animation={animations3.text}  opacity={animations3.opacity} />
                    </Center>
                    <Box position='relative' transition='0.5s' mt={5}>
                        <Image ml='2.1rem' src={laptop} height='auto' w={{ base: '19rem', sm: '19rem', md: '25rem' }} mr={10}  animation={animations3.image}/>
                        <Image src={getintouch} position='absolute' top={{ base: '2rem', sm: '2rem', md: '4rem' }} animation={getTouch} right='20px' height='auto' w={{ base: '120px', sm: '120px', md: '150px' }} />
                    </Box>
                    <Flex gap={7} justifyContent='center' w='100%'>
                        <Box w='30%' minW='20%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Web App Development1</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>iOS App Development1</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Graphics Design1</Text>
                            </Flex>
                        </Box>

                        <Box w='30%' mt='1rem'>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} mt={9} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>Android App Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>CMR/ERP Development</Text>
                            </Flex>
                            <Flex gap={{ base: 2, sm: 2, md: 6 }} my={6} animation={animations3.text}  opacity={animations3.opacity}>
                                <FaStarOfLife color='#4f4e4e' fontSize='13' />
                                <Text color='white' fontSize={{ sm: '11', base: '11', md: '14' }}>UI/UX Design</Text>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>} */}
            </Box>

        </Box>
    )
}

export default Branches