import React from 'react';
import { Flex, Image, Box, Text, Button, FormControl, FormLabel, Input, Center } from '@chakra-ui/react'
import plusimg from "../assets/plus.webp";
import Downarrow from "../assets/Downarrow.webp";
import Arrow from "../assets/Arrow.webp"
import ArrowDown from '../assets/ArrowDown.webp';
import mobilePlus from '../assets/section.webp';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';

const Plus = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Box h={{ base: '100%', sm: '100%', md: '100%', lg: '100vh' }} overflow='hidden' width='100%' background='#000' mt='4rem' id='about_us'> 
                <div className="logo-scroll"  >
                    <div className="logo-scroll-slide" fontWeight='100' fontSize='15px'>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Content Creation </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Digital Transformation  </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Web / Mobile Application Development  </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden"> Gamification / AR / VR</Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Marketing Consulting</Text>
                    </div>
                    <div className="logo-scroll-slide" fontWeight='100' fontSize='15px'>
                        {/* <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden"> {" "} {" "}</Text> */}
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Content Creation </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Digital Transformation  </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Web / Mobile Application Development  </Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden"> Gamification / AR / VR</Text>
                        <img className="overflow-hidden" src={Arrow} alt='' />
                        <Text as='span' borderTop='1px' borderBottom='1px' borderStyle='solid' borderColor="transparent" p='15px' className="overflow-hidden">Marketing Consulting</Text>
                    </div>
                </div>
                {/* laptop view */}
                <Box bgImage={plusimg} bgRepeat='no-repeat' zIndex='1' bgSize={{'2xl':'90% 110vh',xl:'90% 121vh',lg:'90% 122vh',md:'90% 110vh'}} bgPosition={{ '2xl': '50% -7rem', xl: '50% -6.2rem', lg: '50% -6.3rem', md: '50% -5.5rem' }}
                    overflow="hidden" alignContent="center" display={{ base: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block', '2xl': 'block' }}>
                    <Flex justifyContent='space-between' w='100%' mt={{ '2xl': '7%', xl: '6%', lg: '3%', md: '15%', sm: '20%', base: '10%' }} >
                        <Flex color='#aeaeae' w='40%' justifyContent='center' alignItems='center' mt={{ '2xl': '4%', xl: '5%', lg: '12%', md: '10%', sm: '11%', base: '12%' }} mr='20px'>
                            <Text fontSize='30px' lineHeight='1em' fontWeight='300'> Ab<Text as='span' fontFamily="Pixelify Sans, sans-serif !important" color=' #ff5f35' fontSize='40px' >o</Text>ut
                                <br /> <Text as='span' fontSize='30px' fontWeight='300' color='white'>Marworx</Text>
                            </Text>
                        </Flex>
                        <Flex color='#aeaeae' width='33%' justifyContent='center' alignItems='center' mt={{ '2xl': '4%', xl: '5%', lg: '12%', md: '9%', sm: '11%', base: '15%' }}>
                            <Text textAlign='justify' fontWeight='100' fontSize={{ '2xl': '14', xl: '10', lg: '11px', md: '10px', sm: '10px', base: '10px' }} pr={5}>
                                At Marworx, we're not just a team of digital marketing enthusiasts; we're passionate strategists
                                dedicated to crafting dynamic campaigns that drive exponential growth. As an innovative advertising agency,
                                we thrive on generating ideas that propel brands to new heights, infusing every project with creativity and a relentless pursuit of excellence.
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex justifyContent='space-between' w='100%' mt={{ '2xl': '6%', xl: '7%', lg: '8%', md: '10%', sm: '6%', base: '10%' }} alignItems='center'>
                        <Flex color='#aeaeae' transform='rotate(270deg)' width='60%' justifyContent="center" ml={20}>
                            <Image src={Downarrow} width={{ md: '4%' }} />
                        </Flex>
                        <Flex color='#aeaeae' justifyContent='start' width='50%' flexDirection='column'>
                            <Flex position='relative'  >
                                <Text fontSize={{ '2xl': '15', xl: '13', lg: '11px', md: '10px', sm: '10px', base: '10px' }} fontWeight='100'>
                                    <Text as='Text' color='#ff5f35'>Join us </Text>as we redefine success in the
                                    <br />
                                    digital landscape, one breakthrough at  <br /> a time.
                                </Text>
                            </Flex>
                            <Flex alignContent='center' color='#ff5f35'>
                                <Button textAlign='center' ml='-1em' bg='none' _hover={{ bg: ' #aeaeae4f',color:'#ff5f35' ,boxShadow:' 0px 0px 4px 2px #ff5722a1'}} color='#ff5f35' onClick={onOpen} fontSize={{ '2xl': '15', xl: '13', lg: '11px', md: '10px', sm: '10px', base: '10px' }} fontWeight='400'>  Get in touch</Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Box>
                

                {/* mobile view */}
                <Box display={{ base: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none', '2xl': 'none' }} zIndex='1' >
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='center'
                        position='relative'
                        top={{ md: '-3.3rem', sm: '-2rem', base: '-5.35rem' }}
                    >
                        <Box transform={{ base: 'scale(1)', sm: 'scale(1.5)', md: 'scale(1.3)' }}>
                            <Image src={mobilePlus} w={{ md: '72rem !important', sm: '50rem !important', base: '42rem !important' }} maxWidth='max-content' h='auto' />
                        </Box>
                        <Box
                            mt={{ base: '-90px', sm: '-30px', md: '-30px' }}
                            // position='absolute'
                            // bottom={{ md: '-8rem', sm: '-7rem', base: '-5rem' }}
                            w={{ md: '60%', sm: '65%', base: '80%' }}
                        >
                            <Text fontSize={{ md: '25px', sm: '17px', base: '14px' }} lineHeight='12px' color='#aeaeae' fontWeight='300'> Ab<Text as='span' fontFamily="Pixelify Sans, sans-serif !important" color=' #ff5f35' fontSize={{ md: '32px', sm: '22px', base: '18px' }} >o</Text>ut
                            </Text>
                            <Text as='span' fontSize={{ md: '23px', sm: '17px', base: '14px' }} fontWeight='300' color='white'>Marworx</Text>
                            <Flex color='#aeaeae' width='max-content' w='100%' textAlign='justify' mt='10px'  >
                                <Text fontWeight='100' fontSize={{ md: '14px', sm: '12px', base: '10px' }} mt='5px'>
                                    At Marworx, we're not just a team of digital marketing enthusiasts; we're passionate strategists
                                    dedicated to crafting dynamic campaigns that drive exponential growth. As an innovative advertising agency,
                                    we thrive on generating ideas that propel brands to new heights, infusing every project with creativity and a relentless pursuit of excellence.
                                </Text>
                            </Flex>
                        </Box>
                        <Box
                            // position='absolute'
                            // bottom={{ md: '-27rem', sm: '-15rem', base: '-13rem' }}
                            my='10px'
                            transform={{ base: 'scale(0.7)' }}
                        >
                            <Image src={ArrowDown} />
                        </Box>
                        <Box
                            // position='absolute'
                            // left={{ md: '12rem', sm: '5rem', base: '4rem' }}
                            textAlign='center'
                            bottom={{ md: '-27rem', sm: '-21rem', base: '-18rem' }}
                            w={{ md: '52%', sm: '61%', base: '71%' }}
                        >
                            <Text fontSize={{ md: '15px', sm: '12px', base: '10px' }} fontWeight='100' color='#aeaeae '>
                                <Text as='span' fontWeight='100' color='#ff5f35'>Join us </Text>as we redefine success in the

                                digital landscape, one breakthrough at a time.
                            </Text>
                            {/* <Text textAlign='center' fontSize={{ md: '15px', sm: '12px', base: '10px' }} color='#ff5f35' fontWeight='600' mt='30px'>  Get in touch</Text> */}
                            {/* <Flex textAlign='center' color='#ff5f35'> */}
                                <Button textAlign='center' bg='none' _hover={{ bg: '#aeaeae4f',color:'#ff5f35' ,boxShadow:' 0px 0px 4px 2px #ff5722a1'}} color='#ff5f35' onClick={onOpen} fontSize={{ md: '15px', sm: '12px', base: '10px' }} fontWeight='400'>  Get in touch</Button>
                            {/* </Flex> */}
                        </Box>
                    </Flex>
                </Box>

                <Modal isOpen={isOpen} onClose={onClose} bg='#313030 !important'>
                    <ModalOverlay />
                    <ModalContent  bg='#313030 !important'>
                        <ModalHeader color='#ff5f35' fontSize='17px' fontWeight='600' textAlign='center'>Contact</ModalHeader>
                        <ModalCloseButton   fontSize='18px'/>
                        <ModalBody>
                          <form>
                          <FormControl my='1px'>
                                <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'> Name</FormLabel>
                                <Input
                                    color='#aeaeae'
                                    _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                    // variant='flushed'
                                    type='email'
                                    name='email'
                                    // value={values.email}
                                    // onChange={handleChange} 
                                    required
                                    />
                              
                            </FormControl>
                            <FormControl my='1px'>
                                <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'> Number</FormLabel>
                                <Input
                                    color='#aeaeae'
                                    _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                    // variant='flushed'
                                    type='email'
                                    name='email'required
                                    // value={values.email}
                                    // onChange={handleChange} 
                                    />
                              
                            </FormControl>
                            <FormControl my='1px'>
                                <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'>Email</FormLabel>
                                <Input
                                    color='#aeaeae'
                                    _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }} fontSize='15px' fontWeight='400'
                                    // variant='flushed'
                                    type='email'
                                    name='email'required
                                    // value={values.email}
                                    // onChange={handleChange} 
                                    />
                              
                            </FormControl>
                            <FormControl my='1px'>
                                <FormLabel color='#ff5f35' fontSize='15px' fontWeight='400'>Company Name</FormLabel>
                                <Input
                                    color='#aeaeae'
                                    _focus={{ borderColor: "#aeaeae", boxShadow: 'none' }}fontSize='15px' fontWeight='400'
                                    // variant='flushed'
                                    type='email'
                                    name='email'required
                                    // value={values.email}
                                    // onChange={handleChange} 
                                    />
                              
                            </FormControl>
                            <Box textAlign='center'm='10px'>
                            <Button  textAlign='center' _hover={{ bg:'black', color:'#ff5f35'}} bg='black' color='#ff5f35' mr={3} onClick={onClose}>
                                Submit
                            </Button>
                        </Box>
                          </form>
                        {/* <Divider /> */}
                        <Center>
                        <Text as='a' color='#ff5f35' fontSize='15px' p='10px'  href='tel:09730215653' fontWeight='400' >Reach us on: 09730215653 </Text>
                        </Center>
                       
                        </ModalBody>

                    
                    </ModalContent>
                </Modal>
            </Box>
        </>
    )
}

export default Plus